import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExamsSummaryOrderBy, ExamsSummaryOrderDirection } from '../../../graphql/types.gen.ts';
import { RootState } from '../../../redux/store.ts';
import { setChosenGroup } from '../../../redux/userSlice.ts';
import { useMeQuery } from '../LoginPage/loginPage.gen.ts';
import { chooseGroupSchema } from './chooseGroupSchema.ts';
import { useCodesQuery } from './chooseGroups.gen.ts';

export const ChooseGroup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const from = location.state?.from || '/licytacja';

    const user = useSelector((state: RootState) => state.user);
    const { data, loading, error } = useCodesQuery({});
    const { data: me } = useMeQuery({
        variables: {
            input: {
                ordering: {
                    orderBy: ExamsSummaryOrderBy.Tag,
                    orderDirection: ExamsSummaryOrderDirection.Desc,
                },
            },
        },
    });
    console.log(user, me);

    useEffect(() => {
        console.log(!user.chosenGroup, user.chosenGroup !== null, !loading);
        if (!data || loading) return;
        if (!user.chosenGroup && !loading) {
            dispatch(setChosenGroup('general'));
        }
        if (data?.me.groups.length <= 1) {
            dispatch(setChosenGroup(data?.me.groups[0].code));
            navigate(from);
        }
    }, [data, loading]);

    const handleSubmit = (values: any) => {
        const chosenGroup = values.chooseGroup;
        dispatch(setChosenGroup(chosenGroup));
        navigate(from);
    };

    return (
        <div className="formPage">
            <h1 className="formPage__title">Wybierz grupę</h1>
            <p className="formPage__desc">Super, należysz do wielu grup! Wybierz, którą chcesz teraz przejrzeć</p>
            <Formik
                initialValues={{
                    chooseGroup: '',
                }}
                validationSchema={chooseGroupSchema}
                onSubmit={(v) => {
                    handleSubmit(v);
                }}
                enableReinitialize={true}
            >
                {(formik) => {
                    const { errors, touched, isValid, dirty, submitForm } = formik;

                    return (
                        <div className="formPage__formContainer">
                            <Form>
                                <div className="formPage__formGroup form-row">
                                    <Field as="select" type="chooseGroup" placeholder="" name="chooseGroup" id="chooseGroup" className={`formPage__formControl ${errors.chooseGroup && touched.chooseGroup ? 'input-error' : ''}`}>
                                        <option disabled value="">
                                            (Wybierz grupę*)
                                        </option>
                                        {data?.me.groups.map((group) => {
                                            return (
                                                <option key={group.code} value={group.code}>
                                                    {group.code}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                    <ErrorMessage name="chooseGroup" component="span" className="error" />
                                </div>
                                <button type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)} onClick={submitForm}>
                                    Wybierz
                                </button>
                            </Form>
                            {error && <p className="formPage__error">{error.message}</p>}
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};
