import React, { useEffect, useRef } from 'react';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import { useValidateToken } from '../../helpers/useValidateToken.ts';
import './Game.scss';
import { GameTest } from './components/GameTest.tsx';
export type IResult = 'BAD' | 'HALF' | 'FULL' | null;

export const Game = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    const container = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        container.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, []);

    // const { drawTestById, chosenGroup, handleResult, randomTest, userBids, handleRecentBids, recentBids, drawTest, handleUserBids, lastUserBid, bestAnswers, handleNextTest, repeatTest, southCards, result } = useGame();
    // const user = useSelector((state: RootState) => state.user);
    // const [currentDepth, setCurrentDepth] = useState(0);

    // console.log(recentBids);
    // if (!randomTest) return;

    useValidateToken({ optional: true });
    return (
        <div className="game wrapper wrapper--spaced">
            {/* {(testLoading || !randomTest?.id) && <Loading />} */}
            {/* <AllTestsDrawer drawTestById={drawTestById} /> */}
            <Header />
            <div className="container" ref={container}>
                {/* <div className="game__title">
                    <Button
                        onClick={() => {
                            dispatch(openAllTestsDrawer())
                        }}
                        className="game__listButton"
                        Icon={ListIcon}
                        text="Filtry"
                        variant="ghost-white-green"
                    />
                </div> */}
                {/* <span className="game__testName">{randomTest.name}</span> */}
                <GameTest />
            </div>
            <div className="game__mobileFiller"></div>
            <Footer />
        </div>
    );
};

export default Game;
