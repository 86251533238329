import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScoreDocument } from '../../components/Header/header.gen.ts';
import { BiddingView, ExamsSummaryOrderBy, ExamsSummaryOrderDirection } from '../../graphql/types.gen.ts';
import { compareTwoBids, compareTwoBidsWithoutAlert } from '../../helpers.ts';
import { RootState } from '../../redux/store.ts';
import { setChosenGroup } from '../../redux/userSlice.ts';
import { useMeQuery } from '../FormPage/LoginPage/loginPage.gen.ts';
import { IResult } from './Game';
import { useExamFromGroupLazyQuery, useExamResultsSaveMutation, usePublicGroupsLazyQuery } from './game.gen.ts';

export const useGame = (id?: string, onSubmit?: () => void) => {
    const [userBids, setUserBids] = useState<BiddingView[]>([]);
    const [lastUserBid, setLastUserBid] = useState<BiddingView>({});
    const [bestAnswers, setBestAnswers] = useState<BiddingView[]>([]);
    const [result, setResult] = useState<IResult>(null);
    const [recentBids, setRecentBids] = useState<BiddingView[]>([]);
    const { token } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const { chosenGroup, isInitLoading } = useSelector((state: RootState) => state.user);

    const { data: userData } = useMeQuery({
        variables: {
            input: {
                ordering: {
                    orderBy: ExamsSummaryOrderBy.Tag,
                    orderDirection: ExamsSummaryOrderDirection.Desc,
                },
            },
        },
    });

    // console.log(chosenGroup, 'chosenGroup');

    // useEffect(() => {
    //     if (chosenGroup) {
    //         return;
    //     } else if (userData?.me.groups.length) {
    //         dispatch(setChosenGroup(userData?.me.groups[0].code));
    //     } else if (!chosenGroup && userData?.me.groups.length === 0) {
    //         dispatch(setChosenGroup('general'));
    //     }
    // }, [userData, chosenGroup]);

    const handleRecentBids = (bids: BiddingView[]) => {
        setRecentBids(bids);
    };
    const [examResultsSaveMutation] = useExamResultsSaveMutation();
    const [getExam, { data: testData, loading: testLoading, error: testError }] = useExamFromGroupLazyQuery({ fetchPolicy: 'no-cache' });
    const [getPublicExam, { data: publicTestData, loading: publicTestLoading, error: publicTestError }] = usePublicGroupsLazyQuery({ fetchPolicy: 'no-cache' });
    const fetchExam = chosenGroup && chosenGroup !== 'general' ? getExam : getPublicExam;
    const loading = chosenGroup && chosenGroup !== 'general' ? testLoading : publicTestLoading;
    const error = chosenGroup && chosenGroup !== 'general' ? testError : publicTestError;

    useEffect(() => {
        if (!chosenGroup) {
            if (userData?.me.groups.length) {
                dispatch(setChosenGroup(userData?.me.groups[0].code));
            } else if (!chosenGroup && userData?.me.groups.length === 0) {
                dispatch(setChosenGroup('general'));
            }
        }
        id ? drawTestById(id) : drawTest();
    }, [chosenGroup, id, userData?.me?.groups]);

    const reset = () => {
        // console.log('reset');
        setUserBids([]);
        setLastUserBid({});
        setBestAnswers([]);
        setResult(null);
    };

    const randomTest = chosenGroup && chosenGroup !== 'general' ? testData?.group?.exam : publicTestData?.publicGroups[0]?.exam;
    // console.log(randomTest);
    useEffect(() => {
        if (!userBids.length) return;
        setLastUserBid(userBids.slice(-1)[0]);
        // console.log(userBids);
    }, [userBids]);

    const handleResult = (r: IResult) => {
        setResult(r);
    };

    const drawTest = () => {
        reset();
        fetchExam({
            variables: {
                code: chosenGroup || 'general',
                input: {
                    random: {
                        difficultyLevel: [],
                        tags: [],
                    },
                },
            },
        });
    };
    const repeatTest = () => {
        reset();
        fetchExam({
            variables: {
                code: chosenGroup || 'general',
                input: {
                    id: randomTest?.id,
                },
            },
        });
    };
    const drawTestById = (id: string) => {
        reset();
        fetchExam({
            variables: {
                code: chosenGroup || 'general',
                input: {
                    id: id,
                },
            },
        });
    };
    const handleNextTest = () => {
        reset();
        if (!randomTest?.id) return;
        fetchExam({
            variables: {
                code: chosenGroup || 'general',
                input: {
                    next: {
                        filters: {
                            difficultyLevel: [],
                            tags: [],
                        },
                        startFromId: randomTest?.id,
                    },
                },
            },
        });
    };
    const sendResultToBackend = (b) => {
        examResultsSaveMutation({
            variables: {
                input: {
                    examId: randomTest.id,
                    answers: [...userBids, { ...b }],
                    groupCode: chosenGroup,
                    revisionId: randomTest.revisionId,
                },
            },
            refetchQueries: [ScoreDocument],
        }).then(() => {
            onSubmit && onSubmit();
        });
    };

    const southCards = useMemo(() => {
        return randomTest?.definition.cards;
    }, [randomTest?.definition.cards]);
    const handleUserBids = (b: BiddingView) => {
        if (compareTwoBids(b, randomTest.definition.answers[userBids.length].halfAnswers[0]) || compareTwoBids(b, randomTest.definition.answers[userBids.length].halfAnswers[1])) {
            setResult('HALF');
            setBestAnswers([randomTest.definition.answers[userBids.length].matchingAnswer]);
            token && sendResultToBackend(b);

            //TODO: replace this when backend sends array of matching answers
            // } else if (compareTwoBids(b, randomTest.definition.answers[userBids.length].matchingAnswer[0]) || compareTwoBids(b, randomTest.definition.answers[userBids.length].matchingAnswer[1])) {
        } else if (compareTwoBids(b, randomTest.definition.answers[userBids.length].matchingAnswer) || compareTwoBids(b, randomTest.definition.answers[userBids.length].matchingAnswer[1])) {
            if (userBids.length === randomTest.definition.answers.length - 1) {
                setResult('FULL');
                token && sendResultToBackend(b);
            }
        } else {
            setBestAnswers([randomTest.definition.answers[userBids.length].matchingAnswer]);
            if (compareTwoBidsWithoutAlert(b, randomTest.definition.answers[userBids.length].matchingAnswer)) {
                setResult('HALF');
            } else {
                setResult('BAD');
            }
            token && sendResultToBackend(b);
        }
        setUserBids((prev) => [...prev, { ...b }]);
    };

    return {
        testError: error,
        isInitLoading,
        randomTest,
        testLoading: loading,
        drawTestById,
        chosenGroup,
        handleResult,
        userBids,
        handleRecentBids,
        recentBids,
        drawTest,
        handleUserBids,
        lastUserBid,
        bestAnswers,
        handleNextTest,
        repeatTest,
        southCards,
        result,
    };
};
