import * as Types from '../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScoreQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type ScoreQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', me?: { __typename?: 'GroupMemberView', score: number } | null } | null };


export const ScoreDocument = gql`
    query Score($code: String!) {
  group(code: $code) {
    me {
      score
    }
  }
}
    `;

/**
 * __useScoreQuery__
 *
 * To run a query within a React component, call `useScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoreQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useScoreQuery(baseOptions: Apollo.QueryHookOptions<ScoreQuery, ScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScoreQuery, ScoreQueryVariables>(ScoreDocument, options);
      }
export function useScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScoreQuery, ScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScoreQuery, ScoreQueryVariables>(ScoreDocument, options);
        }
export function useScoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ScoreQuery, ScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ScoreQuery, ScoreQueryVariables>(ScoreDocument, options);
        }
export type ScoreQueryHookResult = ReturnType<typeof useScoreQuery>;
export type ScoreLazyQueryHookResult = ReturnType<typeof useScoreLazyQuery>;
export type ScoreSuspenseQueryHookResult = ReturnType<typeof useScoreSuspenseQuery>;
export type ScoreQueryResult = Apollo.QueryResult<ScoreQuery, ScoreQueryVariables>;