import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { CustomApolloProvider } from './graphql/client.tsx';
import './index.css';
import { persistor, store } from './redux/store.ts';
import reportWebVitals from './reportWebVitals.js';
import { ROUTER } from './router.tsx';
import { THEME } from './theme.ts';

const root = ReactDOM.createRoot(document.getElementById('root'));

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={THEME}>
                <CustomApolloProvider>
                    <BrowserRouter>
                        <ScrollToTop />
                        {/* If we ever need cookies: */}
                        {/* <CookieConsent location="bottom" buttonText="Akceptuję" cookieName="myAppCookieConsent" style={{ background: '#040F01' }} buttonStyle={{ color: '#040F01', fontSize: '13px', borderRadius: '5px', backgroundColor: '#C48208' }} expires={150}>
                            Licytapka używa ciasteczek, żeby strona działała sprawnie, była łatwiejsza w obsłudze i lepiej dopasowana do Ciebie. Korzystając z naszej strony, akceptujesz ich użycie.
                        </CookieConsent> */}
                        <Routes>
                            {ROUTER.map((route) => (
                                <Route key={route.path} path={route.path} element={route.element} />
                            ))}
                        </Routes>
                    </BrowserRouter>
                </CustomApolloProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

reportWebVitals();
