import { Paper } from '@mui/material';
import React from 'react';
import { Page } from '../../components/Page/Page.tsx';

export const Terms = () => {
    return (
        <Page title="Regulamin" theme="light">
            <Paper className="lessons__paper paper">
                <div className="singleLesson__wrapper">
                    <h1>Tu pojawi sie regulamin</h1>
                </div>
            </Paper>
        </Page>
    );
};
