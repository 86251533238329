import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogoWhite5 } from '../../assets/LogoWhite5.tsx';
import Linkedin from '../../icons/Linkedin.tsx';
import './Footer.scss';

export const Footer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <div className="footer">
            <div className="footer__top">
                <div className="container">
                    <div className="row justify--between">
                        <div className="footer__col footer__col--first">
                            <button
                                onClick={() => {
                                    navigate('/');
                                }}
                                className="footer__logo"
                            >
                                <div className="header__logo-image">
                                    <LogoWhite5 />
                                </div>
                            </button>
                        </div>
                        <div className="footer__col">
                            <p className="footer__title">Przydatne linki</p>
                            <a href="/about" className="footer__desc">
                                O mnie
                            </a>
                            <a href="/profil" className="footer__desc">
                                Profil
                            </a>
                            <a href="#" className="footer__desc">
                                Regulamin
                            </a>
                            <a href="#" className="footer__desc">
                                Polityka prywatności
                            </a>
                            <a href="/backoffice-info" className="footer__desc">
                                Twórz swoje testy
                            </a>
                        </div>
                        <div className="footer__col">
                            <p className="footer__title">Kontakt</p>
                            <p className="footer__desc">Magdalena Paluch</p>
                            <p className="footer__desc">
                                <a href="mailTo:magdalena@licytapka.pl" className="link">
                                    magdalena@licytapka.pl
                                </a>
                            </p>
                            <a className="footer__desc" href="https://www.linkedin.com/in/magdapaluch/">
                                <Linkedin />
                                magdapaluch
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer__bottom">
                <div className="container">
                    <div className="row justify--between">
                        <p className="footer__version">v{process.env.REACT_APP_VERSION}</p> <p className="footer__bottom-text">Licytapka © {new Date().getFullYear()} Wszystkie prawa zastrzeżone</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
