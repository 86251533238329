import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
    username: string;
    email: string;
    token: string;
    isLogged: boolean;
    chosenGroup: string;
    isInitLoading: boolean;
}

const initialState: UserState = {
    username: '',
    email: '',
    token: '',
    isLogged: false,
    chosenGroup: '',
    isInitLoading: true,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        init: (state) => {
            console.log('init');
            const token = localStorage.getItem('token');
            state.token = token || '';
            const chosenGroup = localStorage.getItem('chosenGroup');
            state.chosenGroup = chosenGroup || '';
            state.isInitLoading = false;
        },
        setToken: (state, action: PayloadAction<string>) => {
            console.log('setToken');
            state.token = action.payload;
            localStorage.setItem('token', state.token);
            localStorage.setItem('unregisteredUser', 'false');
        },
        setUser: (state, action: PayloadAction<{ username: string; email: string }>) => {
            console.log('setUser');
            console.log(action.payload);
            state.username = action.payload.username;
            state.email = action.payload.email;
        },
        logout: (state) => {
            console.log('logout');
            state.token = '';
            localStorage.removeItem('token');
        },
        setChosenGroup: (state, action: PayloadAction<string>) => {
            console.log('setChosenGroup');
            state.chosenGroup = action.payload;
            localStorage.setItem('chosenGroup', action.payload);
        },
        setUnregisteredUser: (state) => {
            console.log('setUnregisteredUser');
            state.chosenGroup = 'general';
            localStorage.setItem('unregisteredUser', 'true');
        },
    },
});
export const { setToken, setUser, logout, setChosenGroup, init, setUnregisteredUser } = userSlice.actions;
export default userSlice.reducer;
