import React from 'react';
import { SequenceItem } from '../../../components/Sequence/SequenceItem.tsx';
import { BiddingCardColorView, BiddingCardValueView, BiddingDeclarationView, BiddingView } from '../../../graphql/types.gen.ts';

export const FlyingBids = ({ direction = 'right' }) => {
    const SEQUENCE_ITEMS: BiddingView[] = [];

    Object.keys(BiddingCardValueView).forEach((value) => {
        Object.keys(BiddingCardColorView).forEach((color) => {
            SEQUENCE_ITEMS.push({
                bidCard: {
                    __typename: 'BiddingCardView',
                    value: BiddingCardValueView[value],
                    color: BiddingCardColorView[color],
                },
                declaration: BiddingDeclarationView.Bid,
            });
        });
        SEQUENCE_ITEMS.push({
            bidCard: undefined,
            declaration: BiddingDeclarationView.Pass,
        });
    });

    SEQUENCE_ITEMS.push({
        bidCard: undefined,
        declaration: BiddingDeclarationView.Double,
    });
    SEQUENCE_ITEMS.push({
        bidCard: undefined,
        declaration: BiddingDeclarationView.Redouble,
    });

    const SHUFFLED_SEQUENCE_ITEMS = SEQUENCE_ITEMS.map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

    return (
        <div className={`home__flying-bids home__flying-bids--${direction}`}>
            {!!SHUFFLED_SEQUENCE_ITEMS.length &&
                [...SHUFFLED_SEQUENCE_ITEMS, ...SHUFFLED_SEQUENCE_ITEMS, ...SHUFFLED_SEQUENCE_ITEMS].map((item, index) => {
                    return <SequenceItem key={index} item={item} classes={'home__flying-bid'} />;
                })}
        </div>
    );
};
