import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from '../../../components/Button/Button.tsx';
import { Footer } from '../../../components/Footer/Footer.tsx';
import { Header } from '../../../components/Header/Header.tsx';
import Hero from '../../../components/Hero/Hero.tsx';
import { Loading } from '../../../components/Loading/Loading.tsx';
import { GroupMemberRoleView } from '../../../graphql/types.gen.ts';
import { RootState } from '../../../redux/store.ts';
import { GameTest } from '../../Game/components/GameTest.tsx';
import { useGetLessonBySlugLazyQuery, useGetLessonsHomeworkBySlugLazyQuery, useLessonDeleteMutation } from '../lessons.gen.ts';
import './SingleLesson.scss';

const SingleLesson = () => {
    const { titleId } = useParams();
    const { chosenGroup } = useSelector((state: RootState) => state.user);
    const [testId, setTestId] = useState('');
    const navigate = useNavigate();
    const [lessonDeleteMutation] = useLessonDeleteMutation();
    const [fetchLesson, { data, loading, error }] = useGetLessonBySlugLazyQuery({
        variables: {
            code: chosenGroup,
            lessonInput2: {
                slug: titleId,
            },
        },
    });
    useEffect(() => {
        fetchLesson();
    }, []);
    const [fetchHomework, { data: lessonsHomework }] = useGetLessonsHomeworkBySlugLazyQuery({
        variables: {
            code: chosenGroup,
            lessonInput2: {
                slug: titleId,
            },
        },
    });
    useEffect(() => {
        fetchLesson();
        fetchHomework();
    }, []);
    const lesson = data?.group?.lesson;
    const isInstructor = data?.group?.me?.role === GroupMemberRoleView.Instructor;
    const editLesson = () => {
        navigate(`/lekcje/edytuj/${titleId}`);
    };
    const deleteLesson = async () => {
        lessonDeleteMutation({
            variables: {
                input: {
                    groupCode: chosenGroup,
                    lessonSlug: lesson.slug,
                },
            },
        }).then((response) => {
            if (response.data.lessonDelete.__typename === 'LessonDeleteSuccessView') {
                navigate('/nauka');
            } else {
                toast.error('Nie udało się usunąć lekcji');
            }
        });
    };
    if (!lesson) return null;
    if (loading) return <Loading />;
    console.log(lesson.content);
    return (
        <div className="lessons light">
            <div className="content">
                <ToastContainer />
                <Header light={true} />
                <Hero title={lesson.title} />
                <div className="lessons__container container">
                    <div className="row justify--center">
                        <Button variant="ghost-black" className="lessons__edit" onClick={() => navigate('/nauka')} text="Wróć do listy" />
                        {isInstructor && <Button className="lessons__edit" onClick={editLesson} text="Edytuj" />}
                        {isInstructor && <Button variant="red" className="lessons__edit" onClick={deleteLesson} text="Usuń" />}
                    </div>

                    <Paper className="lessons__paper paper">
                        <div className="singleLesson__wrapper">
                            <p className="allTests__tag">{lesson.tags[0]}</p>
                            <div className="lessons__content" dangerouslySetInnerHTML={{ __html: lesson.content }} />
                        </div>
                    </Paper>
                    <div className="singleLesson__testList">
                        {!!lessonsHomework?.group?.lesson?.homework.length && <h3 className="singleLesson__title">Testy do wykonania:</h3>}
                        {lessonsHomework?.group?.lesson?.homework.map((homework, index) => (
                            <button
                                className={`singleLesson__button singleLesson__button--${homework.myHighestResult} singleLesson__button--${homework.id === testId ? 'active' : 'inactive'}`}
                                onClick={() => {
                                    setTestId(homework.id);
                                }}
                            >
                                Test #{index + 1}
                            </button>
                        ))}
                    </div>
                    {testId && (
                        <Paper className="lessons__paper lessons__paper--game paper">
                            <GameTest id={testId} forLesson={true} onSubmit={fetchHomework} />
                        </Paper>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SingleLesson;
