import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button.tsx';
import { Cards } from '../../../components/Cards/Cards.tsx';
import { ExamDefinitionView } from '../../../graphql/types.gen.ts';
import { Section } from './Section/Section.tsx';

const DEMO_TEST: ExamDefinitionView = {
    __typename: 'ExamView',
    definition: {
        __typename: 'ExamDefinitionView',
        answers: [
            {
                __typename: 'ExamAnswerView',
                artificialId: '$.BID_ONE_CLUBS',
                biddingSequence: [
                    {
                        __typename: 'BiddingView',
                        bidCard: null,
                        declaration: 'PASS',
                    },
                    {
                        __typename: 'BiddingView',
                        bidCard: {
                            __typename: 'BiddingCardView',
                            color: 'HEARTS',
                            value: 'ONE',
                        },
                        declaration: 'BID',
                    },
                    {
                        __typename: 'BiddingView',
                        bidCard: null,
                        declaration: 'PASS',
                    },
                ],
                correctness: 'FULL',
                matchingAnswer: {
                    __typename: 'BiddingView',
                    bidCard: {
                        __typename: 'BiddingCardView',
                        value: 'ONE',
                        color: 'CLUBS',
                    },
                    declaration: 'BID',
                },
                subAnswerIds: ['$.BID_ONE_CLUBS.BID_ONE_SPADES', '$.BID_ONE_CLUBS.BID_ONE_SPADES.BID_THREE_HEARTS', '$.BID_ONE_CLUBS.BID_ONE_SPADES.BID_THREE_SPADES', '$.BID_ONE_CLUBS.BID_TWO_DIAMONDS'],
            },
            {
                __typename: 'ExamAnswerView',
                artificialId: '$.BID_ONE_CLUBS.BID_ONE_SPADES',
                biddingSequence: [
                    {
                        __typename: 'BiddingView',
                        bidCard: null,
                        declaration: 'PASS',
                    },
                    {
                        __typename: 'BiddingView',
                        bidCard: {
                            __typename: 'BiddingCardView',
                            color: 'CLUBS',
                            value: 'TWO',
                        },
                        declaration: 'BID',
                    },
                    {
                        __typename: 'BiddingView',
                        bidCard: null,
                        declaration: 'PASS',
                    },
                ],
                correctness: 'FULL',
                matchingAnswer: {
                    __typename: 'BiddingView',
                    bidCard: {
                        __typename: 'BiddingCardView',
                        value: 'ONE',
                        color: 'SPADES',
                    },
                    declaration: 'BID',
                },
                subAnswerIds: ['$.BID_ONE_CLUBS.BID_ONE_SPADES.BID_THREE_HEARTS', '$.BID_ONE_CLUBS.BID_ONE_SPADES.BID_THREE_SPADES'],
            },
            {
                __typename: 'ExamAnswerView',
                artificialId: '$.BID_ONE_CLUBS.BID_ONE_SPADES.BID_THREE_HEARTS',
                biddingSequence: [],
                correctness: 'FULL',
                matchingAnswer: {
                    __typename: 'BiddingView',
                    bidCard: {
                        __typename: 'BiddingCardView',
                        value: 'THREE',
                        color: 'HEARTS',
                    },
                    declaration: 'BID',
                },
                subAnswerIds: [],
            },
            {
                __typename: 'ExamAnswerView',
                artificialId: '$.BID_ONE_CLUBS.BID_ONE_SPADES.BID_THREE_SPADES',
                biddingSequence: [],
                correctness: 'HALF',
                matchingAnswer: {
                    __typename: 'BiddingView',
                    bidCard: {
                        __typename: 'BiddingCardView',
                        value: 'THREE',
                        color: 'SPADES',
                    },
                    declaration: 'BID',
                },
                subAnswerIds: [],
            },
            {
                __typename: 'ExamAnswerView',
                artificialId: '$.BID_ONE_CLUBS.BID_TWO_DIAMONDS',
                biddingSequence: [],
                correctness: 'HALF',
                matchingAnswer: {
                    __typename: 'BiddingView',
                    bidCard: {
                        __typename: 'BiddingCardView',
                        value: 'TWO',
                        color: 'DIAMONDS',
                    },
                    declaration: 'BID',
                },
                subAnswerIds: [],
            },
        ],
        cards: [
            {
                __typename: 'CardView',
                color: 'SPADES',
                value: 'ACE',
            },
            {
                __typename: 'CardView',
                color: 'SPADES',
                value: 'QUEEN',
            },
            {
                __typename: 'CardView',
                color: 'SPADES',
                value: 'EIGHT',
            },
            {
                __typename: 'CardView',
                color: 'SPADES',
                value: 'SEVEN',
            },
            {
                __typename: 'CardView',
                color: 'SPADES',
                value: 'FOUR',
            },
            {
                __typename: 'CardView',
                color: 'HEARTS',
                value: 'KING',
            },
            {
                __typename: 'CardView',
                color: 'HEARTS',
                value: 'JACK',
            },
            {
                __typename: 'CardView',
                color: 'HEARTS',
                value: 'TWO',
            },
            {
                __typename: 'CardView',
                color: 'DIAMONDS',
                value: 'ACE',
            },
            {
                __typename: 'CardView',
                color: 'DIAMONDS',
                value: 'KING',
            },
            {
                __typename: 'CardView',
                color: 'DIAMONDS',
                value: 'TWO',
            },
            {
                __typename: 'CardView',
                color: 'CLUBS',
                value: 'QUEEN',
            },
            {
                __typename: 'CardView',
                color: 'CLUBS',
                value: 'FIVE',
            },
        ],
        initialBiddingSequence: [],
    },
    difficultyLevel: 'HARD',
    id: '65eca22fcc24fe1d01bac820',
    name: 'Test 8',
    tags: ['MAGISTER'],
};

export const Demo = () => {
    const navigate = useNavigate();

    const goToGame = () => {
        navigate('/licytacja');
    };

    return (
        <Section variant="lightGreen" title="Co powinien zalicytować gracz S?" desc="Na podstawie karty gracza S i sekwencji licytacyjnej na stoliku, wybierz najlepszą odzywkę gracza S">
            <div className="container">
                <div className="row justify--center">
                    <div className="home__demo-wrapper">
                        <div className="home__demo-image">
                            <img src="./images/seq3.png" alt="seq-bids" />
                        </div>
                        <div className="home__demo-content">
                            <Cards cards={DEMO_TEST.definition.cards || Array.from({ length: 13 })} />
                            <p className="cards__title">Karty gracza S</p>
                        </div>
                    </div>
                </div>
            </div>
            <Button customClass={`section__cta`} onClick={goToGame} text="Rozpocznij grę" />
        </Section>
    );
};
