import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import Hero from '../../components/Hero/Hero.tsx';
import '../FormPage/FormPage.scss';
import { ImageSection } from '../Home/components/ImageSection/ImageSection.tsx';
import { Section } from '../Home/components/Section/Section.tsx';
import './BackofficeInfo.scss';
import { useGroupCreateMutation } from './backofficeInfo.gen.ts';

import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { GroupCreateFailureReason } from '../../graphql/types.gen.ts';
import { useValidateToken } from '../../helpers/useValidateToken.ts';
import { setChosenGroup } from '../../redux/userSlice.ts';
export const groupSchema = Yup.object().shape({
    name: Yup.string().required('Nazwa jest wymagana').min(5, 'Nazwa jest za krótka'),
});

export const BackofficeInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [apiError, setApiError] = useState('');

    const [groupCreateMutation, { error }] = useGroupCreateMutation();
    useValidateToken({ optional: false });
    const handleSubmit = async (values: any) => {
        await groupCreateMutation({
            variables: {
                input: {
                    code: values.name,
                    name: values.name,
                },
            },
        }).then((r) => {
            if (r?.data?.groupCreate.__typename === 'GroupCreateFailureView') {
                switch (r.data.groupCreate.reason) {
                    case GroupCreateFailureReason.GroupCodeAlreadyExists:
                    default:
                        setApiError('Nazwa grupy jest już zajęta');
                        break;
                }
            }
            if (r.data?.groupCreate.__typename === 'GroupCreateSuccessView') {
                dispatch(setChosenGroup(r?.data?.groupCreate?.groupCode));
                navigate('/backoffice');
            }
        });
    };
    return (
        <div className="page backofficeInfo formPage">
            <Header />
            <Hero title="Interaktywna nauka licytacji brydżowej" />
            <ImageSection variant="white">
                <img className="imageSection__image" src="./images/tests.png" alt="" />
                <div className="imageSection__content">
                    <h3 className="section__title">Testy, lekcje i grupy</h3>
                    <p className="section__desc">Stwórz własną grupę i wprowadzaj unikalne przypadki testowe. Dodawaj karty, tagi oraz prawidłowe odpowiedzi, tworząc spersonalizowane materiały do interaktywnej nauki licytacji brydżowej. </p>
                    <p className="section__desc">
                        Testy i lekcje będą dostępne <strong>wyłącznie dla Twojej grupy uczniów.</strong> Będzie potrzebna Twoja zgoda, aby ktoś dołączył do stworzonej przez Ciebie grupy.
                    </p>
                </div>
            </ImageSection>
            <Section variant="lightestGreen">
                <h3 className="section__title">Stwórz grupę</h3>
                <p className="section__desc section__descsection__desc">Po zarejestrowaniu grupy strona automatycznie przeniesie Cię do panelu administracyjnego.</p>
                <Formik
                    initialValues={{
                        name: '',
                    }}
                    validationSchema={groupSchema}
                    onSubmit={(v) => {
                        handleSubmit(v);
                    }}
                    enableReinitialize={true}
                >
                    {(formik) => {
                        const { errors, touched, isValid, dirty, submitForm } = formik;

                        return (
                            <div className="formPage__formContainer formPage__formContainer--short">
                                <Form>
                                    <div className="formPage__formGroup form-row">
                                        <Field type="name" placeholder="Nazwa*" name="name" id="name" className={`formPage__formControl ${errors.name && touched.name ? 'input-error' : ''}`} />
                                        <ErrorMessage name="name" component="span" className="error" />
                                    </div>
                                    <button onClick={submitForm} type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)}>
                                        Zarejestruj grupę
                                    </button>
                                </Form>
                                {error && <p className="formPage__error">{error.message}</p>}
                                {apiError && <p className="formPage__error">{apiError}</p>}
                            </div>
                        );
                    }}
                </Formik>
            </Section>
            <Footer />
        </div>
    );
};
