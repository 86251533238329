import React, { useEffect } from 'react';
import './ActivateAccountPage.scss';
// import { useSignUpMutation } from "./signUpPage.gen.ts";
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../components/Loading/Loading.tsx';
import { AccountActivationErrorReason } from '../../graphql/types.gen.ts';
import { useAccountActivateMutation } from './activateAccount.gen.ts';

const ActivateAccountPage = (props) => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [accountActivateMutation, { data, loading, error }] = useAccountActivateMutation();

    useEffect(() => {
        if (token) {
            accountActivateMutation({
                variables: {
                    input: {
                        accountActivationToken: token,
                    },
                },
            });
        }
    }, [token]);

    useEffect(() => {
        if (!loading && data && data?.accountActivate?.activatedAt) {
            navigate('/login/activated');
        }
    }, [data, loading]);

    return (
        <div className="activateAccount">
            <h2 className="activateAccount__title"></h2>
            {loading && <Loading />}
            {data && data?.accountActivate?.reason === AccountActivationErrorReason.AlreadyActivated && <p>Twoj konto zostało już aktywowane</p>}
            {data && data?.accountActivate?.reason === AccountActivationErrorReason.TokenNotFound && <p>Skontaktuj się z supportem</p>}
        </div>
    );
};
export default ActivateAccountPage;
