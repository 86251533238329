import { useApolloClient } from '@apollo/client';
import { Paper } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import * as Yup from 'yup';
import { DiamondIcon } from '../../assets/DiamondIcon.tsx';
import { JackCrown } from '../../assets/JackCrown.tsx';
import { JokerCrown } from '../../assets/JokerCrown.tsx';
import { KingCrown } from '../../assets/KingCrown.tsx';
import { QueenCrown } from '../../assets/QueenCrown.tsx';
import { UserSmilingBetter } from '../../assets/UserSmilingBetter.tsx';
import { Button } from '../../components/Button/Button.tsx';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import Hero from '../../components/Hero/Hero.tsx';
import { ExamsSummaryOrderBy, ExamsSummaryOrderDirection } from '../../graphql/types.gen.ts';
import { getRankDetailsByPoints } from '../../helpers.ts';
import { useIsMobile } from '../../helpers/useIsMobile.ts';
import { useValidateToken } from '../../helpers/useValidateToken.ts';
import { RootState, persistor } from '../../redux/store.ts';
import { logout, setChosenGroup } from '../../redux/userSlice.ts';
import { useMeQuery } from '../FormPage/LoginPage/loginPage.gen.ts';
import { CustomTooltip } from '../Statistics/components/CustomTooltip.tsx';
import { CustomizedTick } from '../Statistics/components/CustomizedTick.tsx';
import { useStatistics } from '../Statistics/useStatistics.ts';
import './Profil.scss';
import { useProfilStatistics } from './hooks/useProfilStatistics.ts';
import { useAccountDeleteMutation, useGroupJoinRequestCreateMutation, usePasswordChangeMutation } from './profil.gen.ts';

export const passwordChangeSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Hasło jest wymagane').min(8, 'Hasło jest za krótkie'),
    newPassword: Yup.string().required('Hasło jest wymagane').min(8, 'Hasło jest za krótkie'),
});

const COLORS = ['#880518', '#2b8723', '#1b4c16'];

export const joinGroupSchema = Yup.object().shape({
    groupCode: Yup.string().required('Nazwa groupy jest wymagana'),
});

const GROUP_MEMEBER_ROLE_MAP = {
    INSTRUCTOR: 'Trener',
    PUPIL: 'Uczeń',
};
export const Profil = () => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useMeQuery({
        variables: {
            input: {
                ordering: {
                    orderBy: ExamsSummaryOrderBy.Tag,
                    orderDirection: ExamsSummaryOrderDirection.Desc,
                },
            },
        },
    });
    const { chosenGroup } = useSelector((state: RootState) => state.user);
    const { statisticsData, statisticsPublicData, leaderboardsActivityData, leaderboardsRatioData } = useProfilStatistics();
    const { isMobile } = useIsMobile();
    const [isPasswordInputShown, setIsPasswordInputShown] = useState(false);
    const statisticsSource = statisticsData?.group || statisticsPublicData?.publicGroups[0];

    const myStatistics = statisticsSource?.me;
    const myScore = statisticsSource?.me?.score;
    const myExamResults = myStatistics?.examsSummary;
    const { userPassedExamsRatio, transformedTagData } = useStatistics(myExamResults);
    const [groupJoinRequestCreateMutation, { data: groupJoinData }] = useGroupJoinRequestCreateMutation();
    const totalExams = userPassedExamsRatio.reduce((acc, item) => acc + item.value, 0);
    const passedExams = userPassedExamsRatio.find((item) => item.id === 'passed');
    const rankDetails = getRankDetailsByPoints(myScore);
    const rankRatio = (myScore * 100) / (rankDetails.toNextRank + myScore);
    useValidateToken();
    const [passwordChangeMutation, { data: passwordChangeMutationData }] = usePasswordChangeMutation();

    const [accountDeleteMutation, { data: accountDeleteMutationData }] = useAccountDeleteMutation();

    useEffect(() => {
        if (!groupJoinData?.groupJoinRequestCreate) return;
        if (groupJoinData?.groupJoinRequestCreate?.__typename === 'CreateJoinRequestSuccessView') {
            toast.success('Zgłoszenie dołączenia do grupy zostało wysłane');
        } else {
            toast.error(handleJoinRequestError(groupJoinData?.groupJoinRequestCreate?.failureReason));
        }
    }, [groupJoinData]);

    useEffect(() => {
        if (passwordChangeMutationData?.passwordChange.success === true) {
            navigate('/login');
        }
        if (passwordChangeMutationData?.passwordChange && !passwordChangeMutationData.passwordChange.success) {
            toast.error('Ooops, wystąpił błąd');
        }
    }, [passwordChangeMutationData]);

    useEffect(() => {
        if (accountDeleteMutationData?.accountDelete.success) {
            toast.success('Twoje konto zostało usunięte pomyślnie');
        }
        if (accountDeleteMutationData?.accountDelete && !accountDeleteMutationData.accountDelete.success) {
            toast.error('Ooops, wystąpił błąd');
        }
    }, [accountDeleteMutationData]);

    const rankDetailsToIcon = (r: string) => {
        switch (r) {
            case 'jack-crown':
                return <JackCrown />;
            case 'queen-crown':
                return <QueenCrown />;
            case 'king-crown':
                return <KingCrown />;
            case 'joker-crown':
            default:
                return <JokerCrown />;
        }
    };

    const handleJoinRequestError = (reason: string) => {
        switch (reason) {
            case 'GROUP_NOT_FOUND':
                return 'Nie znaleziono grupy o podanym kodzie';
            case 'ALREADY_MEMBER':
                return 'Już jesteś członkiem tej grupy';
            case 'FOUND_PENDING_REQUEST':
                return 'Już wysłałeś prośbę o dołączenie do tej grupy';
            case 'CANNOT_JOIN_PUBLIC_GROUP':
                return 'Nie udało się wysłać prośby o dołączenie do grupy';
            default:
                return 'Nie udało się dołączyć do grupy';
        }
    };

    return (
        <div className="profile light statistics">
            <ToastContainer />
            <div className="content">
                <Header light={true} />
                <Hero title="Profil" />
                <div className="profile__container container">
                    <Paper className="profile__paper  profile__paper--center">
                        <h2 className="profile__paperTitle">Twój profil:</h2>
                        <div className="profile__profileInfo">
                            <div className="profile__avatar">
                                <UserSmilingBetter />
                            </div>
                            <div className="profile__paperCol">
                                <p className="profile__username">{data?.me.username}</p>
                                <p className="profile__email">{data?.me.email}</p>
                                {!isPasswordInputShown && (
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setIsPasswordInputShown(true);
                                        }}
                                    >
                                        Zmień hasło
                                    </Button>
                                )}
                            </div>
                        </div>
                        {isPasswordInputShown && (
                            <Formik
                                initialValues={{
                                    currentPassword: '',
                                    newPassword: '',
                                }}
                                validationSchema={passwordChangeSchema}
                                onSubmit={(values) => {
                                    console.log(values);
                                    passwordChangeMutation({
                                        variables: {
                                            input: values,
                                        },
                                    });
                                }}
                            >
                                {(formik) => {
                                    const { errors, touched, isValid, dirty } = formik;
                                    return (
                                        <>
                                            <Form>
                                                <div className="formPage__formGroup form-row">
                                                    <Field type="password" placeholder="Obecne hasło*" name="currentPassword" id="currentPassword" className={`formPage__formControl ${errors.currentPassword && touched.currentPassword ? 'input-error' : ''}`} />
                                                    <ErrorMessage name="currentPassword" component="span" className="error" />
                                                </div>
                                                <div className="formPage__formGroup form-row">
                                                    <Field type="password" placeholder="Nowe hasło*" name="newPassword" id="newPassword" className={`formPage__formControl ${errors.newPassword && touched.newPassword ? 'input-error' : ''}`} />
                                                    <ErrorMessage name="newPassword" component="span" className="error" />
                                                </div>
                                                <button type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)}>
                                                    Zmień hasło
                                                </button>
                                            </Form>
                                        </>
                                    );
                                }}
                            </Formik>
                        )}
                    </Paper>
                    <div className="row">
                        <Paper className="profile__paper profile__paper--between profile__paper--40">
                            <h2 className="profile__paperTitle">Twoje punkty</h2>
                            <p className="profile__score">
                                {myScore || 0}
                                <DiamondIcon />
                            </p>
                            <p>
                                Kaźdy dobrze wykonany test dodaje Ci punkty!
                                <br />
                                Ale kaźdy źle wykonany je odejmuje...
                            </p>
                        </Paper>
                        <Paper className="profile__paper profile__paper--center profile__paper--60">
                            <h2 className="profile__paperTitle">Twoja ranga</h2>
                            <div className="profile__rankWrapper">
                                <div className="row justify--between">
                                    <div className="profile__col">
                                        <div className="profile__imgWrapper">{rankDetailsToIcon(rankDetails.img)}</div>
                                    </div>
                                    <div className="profile__col">
                                        <div className="row justify--between">
                                            <p className="profile__rankDetails">{rankDetails.text}</p>
                                            <p className="profile__rankDetailsScore">
                                                {myScore || 0}/{rankDetails.toNextRank + myScore || 0} <DiamondIcon />
                                            </p>
                                        </div>
                                        <div className="profile__progress">
                                            <div className="profile__progress--fill" style={{ width: `${rankRatio}%` }}></div>
                                        </div>
                                        <p className="profile__progress__desc">
                                            Zdobądź {rankDetails.toNextRank + myScore || 0} <DiamondIcon /> by przejść do kolejnej rangi
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p className="profile__progress__witty">{rankDetails.desc}</p>
                        </Paper>
                    </div>
                    <Paper className="profile__paper profile__paper--center">
                        <h2 className="profile__paperTitle">Twoje grupy:</h2>
                        <div className="profile__groups">
                            {data?.me.groups.map((group) => {
                                const shortenedName = group.name.length >= 18 ? group.name.slice(0, 15) + '...' : group.name;
                                const d = new Date(group.me.memberSince);
                                const datestring = d.toISOString().slice(0, 10);
                                const rankDetailsPerGroup = getRankDetailsByPoints(group.me.score);
                                return (
                                    <div key={group.code} className={`profile__group ${chosenGroup === group.code ? 'profile__group--logged' : ''}`}>
                                        <p className="profile__group__name">{group.name === 'general' ? 'Wspólny język - grupa publiczna Licytapki' : group.name}</p>
                                        {chosenGroup === group.code && <p className="profile__group__logged">Obecnie zalogowany</p>}
                                        <div className="profile__group__content">
                                            <div className="profile__group__col profile__group__col--rank">
                                                {rankDetailsToIcon(rankDetails.img)}
                                                <p className="profile__group__rank">{rankDetailsPerGroup.text}</p>
                                            </div>
                                            <div className="profile__group__col">
                                                <p className="profile__group__role">Rola: {GROUP_MEMEBER_ROLE_MAP[group.me.role]}</p>
                                                <p className="profile__group__date">Od: {datestring}</p>
                                                <p className="profile__group__score">
                                                    {group.me.score} <DiamondIcon />
                                                </p>
                                            </div>
                                        </div>
                                        {group.me.role === 'INSTRUCTOR' && (
                                            <button
                                                onClick={() => {
                                                    navigator.clipboard.writeText(group?.code);
                                                    toast.success('Skopiowano kod grupy');
                                                }}
                                                className="profile__group__copy"
                                            >
                                                Skopiuj kod grupy: {shortenedName}
                                            </button>
                                        )}
                                        <div className="profile__group__buttons">
                                            {chosenGroup === group.code ? (
                                                <>
                                                    <Button variant="secondary" onClick={() => navigate('/nauka')}>
                                                        Nauka
                                                    </Button>
                                                    <Button variant="secondary" onClick={() => navigate('/licytacja')}>
                                                        Testy
                                                    </Button>
                                                </>
                                            ) : (
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => {
                                                        dispatch(setChosenGroup(group.code));
                                                        navigate('/profil');
                                                    }}
                                                >
                                                    Zaloguj się do {shortenedName}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}

                            <Formik
                                initialValues={{
                                    groupCode: '',
                                }}
                                validationSchema={joinGroupSchema}
                                onSubmit={(v) => {
                                    groupJoinRequestCreateMutation({ variables: { input: { groupCode: v.groupCode } } });
                                }}
                                enableReinitialize={true}
                            >
                                {(formik) => {
                                    const { errors, touched, isValid, dirty } = formik;

                                    return (
                                        <div className="profile__group">
                                            <p className="profile__group__name">Dołącz do grupy</p>
                                            <p className="profile__group__logged">Jeżeli otrzymałeś kod grupy od trenera, wpisz go poniżej</p>
                                            <Form>
                                                <div className="formPage__formGroup form-row">
                                                    <Field type="groupCode" placeholder="Kod groupy*" name="groupCode" id="groupCode" className={`formPage__formControl ${errors.groupCode && touched.groupCode ? 'input-error' : ''}`} />
                                                    <ErrorMessage name="groupCode" component="span" className="error" />
                                                </div>
                                                <button type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)}>
                                                    Dołącz do grupy
                                                </button>
                                            </Form>
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                        {/* {chosenGroup !== 'general' && (
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    dispatch(setChosenGroup('general'));
                                    navigate('/profil');
                                }}
                            >
                                Zaloguj z powrotem do grupy ogólnej
                            </Button>
                        )} */}
                    </Paper>
                    <div className="row">
                        <Paper className="profile__paper profile__paper--evenly profile__paper--40">
                            <h2 className="profile__paperTitle">Leaderzy grupy</h2>
                            <div className="profile__leaderboard">
                                <h3 className="profile__leaderboard__title">Najwięcej zrobionych testów:</h3>
                                <ol className="profile__leaderboard__list">
                                    {leaderboardsActivityData &&
                                        leaderboardsActivityData.map((member) => {
                                            const totalExams = member.totalExamsNumber;
                                            const username = member.user.username;
                                            return (
                                                <li key={username} className="profile__leaderboard__item">
                                                    {username}: {totalExams}
                                                </li>
                                            );
                                        })}
                                </ol>
                            </div>
                            <div className="profile__leaderboard">
                                <h3 className="profile__leaderboard__title">Najlepsi użytkownicy:</h3>
                                <ol className="profile__leaderboard__list">
                                    {leaderboardsRatioData &&
                                        leaderboardsRatioData.map((member) => {
                                            const totalExams = member.totalExamsNumber;
                                            const passedExams = member.totalPassedExamsNumber;
                                            const username = member.user.username;
                                            return (
                                                <li key={username} className="profile__leaderboard__item">
                                                    {username}: {((passedExams * 100) / totalExams || 0).toFixed()}%
                                                </li>
                                            );
                                        })}
                                </ol>
                            </div>
                        </Paper>
                        <Paper className="profile__paper  profile__paper--center profile__paper--60">
                            <h2 className="profile__paperTitle">Twój postęp</h2>
                            <ul className="profile__ul">
                                <li className="profile__li">
                                    <strong className="profile__columnItemTitle">Wszystkie wykonane testy: </strong>
                                    <span className="profile__columnItemValue">{totalExams}</span>
                                </li>
                                <li className="profile__li">
                                    <strong className="profile__columnItemTitle">Poprawnie wykonane testy: </strong>
                                    <span className="profile__columnItemValue">
                                        {passedExams.ratio}% ({passedExams.value})
                                    </span>
                                </li>
                            </ul>
                            <PieChart width={isMobile ? 300 : 400} height={isMobile ? 300 : 400}>
                                <Legend iconType="circle" />
                                <Pie data={userPassedExamsRatio} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={isMobile ? 100 : 150}>
                                    {userPassedExamsRatio.map((entry, index) => (
                                        <React.Fragment key={index}>
                                            <Cell fill={COLORS[index % COLORS.length]} />
                                            <LabelList fill="#fff" />
                                        </React.Fragment>
                                    ))}
                                </Pie>
                            </PieChart>
                        </Paper>
                    </div>
                    {!isMobile && (
                        <Paper className="profile__paper profile__paper--center">
                            <h2 className="profile__paperTitle">Poszczególne umiejętności</h2>
                            <ResponsiveContainer width="100%" height={500}>
                                <BarChart width={1000} height={500} data={transformedTagData}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#f0cb55" />
                                    <XAxis interval={0} dataKey="tag" allowDecimals={false} tick={<CustomizedTick />} />
                                    <YAxis allowDecimals={false} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend />
                                    <Bar dataKey="Wszystkie testy" fill="#436cb1" />
                                    <Bar dataKey="Poprawnie wykonane testy" fill="#2b8723" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Paper>
                    )}
                    <Button
                        variant="text"
                        onClick={async () => {
                            dispatch(logout());
                            client.cache.reset();
                            dispatch(setChosenGroup(''));
                            await persistor.purge().then(() => {
                                navigate('/');
                            });
                        }}
                    >
                        Wyloguj
                    </Button>
                    <Button
                        variant="textRed"
                        onClick={async () => {
                            await accountDeleteMutation({
                                variables: {
                                    input: {
                                        feedback: '',
                                    },
                                },
                            }).then(async () => {
                                client.cache.reset();
                                dispatch(logout());
                                persistor.purge();
                                navigate('/login');
                            });
                        }}
                    >
                        Usuń konto
                    </Button>
                </div>
            </div>
            <Footer />
        </div>
    );
};
