import React from 'react';
import Bin from '../../../assets/Bin.tsx';
import { Edit } from '../../../assets/Edit.tsx';
import { IconButton } from '../../../components/IconButton/IconButton.tsx';
import { ExamView } from '../../../graphql/types.gen.ts';
import { SmallCards } from '../SmallCards.tsx';
import { useGetBackofficeBidsArray } from '../helpers/useGetBackofficeBidsArray.ts';
import { vulnerabilityToString } from '../helpers/vulnerabilityToString.ts';
import { BackofficeBidSequence } from './BackofficeBidSequence/BackofficeBidSequence.tsx';
import { BackofficeTestEditMode } from './BackofficeTestEditMode.tsx';
interface IBackofficeTest {
    test: ExamView;
    handleEditClick: () => void;
    handleLeaveEditClick: () => void;
    editMode: boolean;
    onSave: (id?: string) => void;
    handleRemoveClick: (id: string) => void;
    index: number;
    isForLesson?: boolean;
}
export const BackofficeTest = (props: IBackofficeTest) => {
    const { test, handleEditClick, editMode, onSave, handleLeaveEditClick, handleRemoveClick, index, isForLesson } = props;
    const { arr } = useGetBackofficeBidsArray(test);
    if (editMode) return <BackofficeTestEditMode isForLesson={isForLesson} index={index} test={test} handleEditClick={handleEditClick} handleLeaveEditClick={handleLeaveEditClick} onSave={onSave} />;
    return (
        <div className="backoffice__test">
            <p className="backoffice__test-number">{index || 1}.</p>
            <div className="backoffice__test-content">
                <div className="backoffice__cards-tags">
                    <div className="backoffice__cards-tags-wrapper">
                        <div className="backoffice__tags">
                            <p className="allTests__tag">{test.tags[0]}</p>
                            {<p className="allTests__tag">level-{test.difficultyLevel}</p>}
                        </div>
                        <SmallCards cards={test.definition.cards} />
                        <p className="backoffice__heading">Karty gracza S</p>
                    </div>
                </div>
                <div className="backoffice__bidSequenceWrapper">
                    <div>
                        <BackofficeBidSequence arr={arr} vulnerability={test?.vulnerability} />
                        <p className="backoffice__heading">{vulnerabilityToString(test.vulnerability)}</p>
                    </div>
                </div>
            </div>
            <IconButton onClick={handleEditClick} Icon={<Edit />} text="Edytuj" />
            <IconButton
                onClick={() => {
                    handleRemoveClick(test.id);
                }}
                Icon={<Bin />}
                text="Usuń"
            />
        </div>
    );
};
