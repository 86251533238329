import * as Types from '../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BackofficeStatisticsQueryVariables = Types.Exact<{
  input: Types.GroupMembersSelectionInput;
  input2: Types.ExamsSummarySelection;
  code: Types.Scalars['String']['input'];
}>;


export type BackofficeStatisticsQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', members: { __typename?: 'GroupMemberViewPaginationResultSetView', items: Array<{ __typename?: 'GroupMemberView', score: number, role: Types.GroupMemberRoleView, memberSince: any, user: { __typename?: 'UserPublicModelView', id: string, username: string }, examsSummary: Array<{ __typename?: 'ExamResultsSummary', passedExamsNumber: number, tag: string, totalExamsNumber: number }> }> }, me?: { __typename?: 'GroupMemberView', memberSince: any, role: Types.GroupMemberRoleView, score: number, examsSummary: Array<{ __typename?: 'ExamResultsSummary', passedExamsNumber: number, tag: string, totalExamsNumber: number }>, user: { __typename?: 'UserPublicModelView', username: string, id: string } } | null } | null };

export type BackofficeStatisticsLeaderboardsQueryVariables = Types.Exact<{
  selection: Types.LeaderboardSelectionInput;
  code: Types.Scalars['String']['input'];
}>;


export type BackofficeStatisticsLeaderboardsQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', leaderboard: Array<{ __typename?: 'LeaderView', score: number, totalExamsNumber: number, totalPassedExamsNumber: number, user: { __typename?: 'UserPublicModelView', id: string, username: string } }> } | null };

export type BackofficeStatisticsPublicLeaderboardsQueryVariables = Types.Exact<{
  selection: Types.LeaderboardSelectionInput;
}>;


export type BackofficeStatisticsPublicLeaderboardsQuery = { __typename?: 'Query', publicGroups: Array<{ __typename?: 'GroupView', leaderboard: Array<{ __typename?: 'LeaderView', totalPassedExamsNumber: number, totalExamsNumber: number, score: number, user: { __typename?: 'UserPublicModelView', id: string, username: string } }> }> };

export type BackofficeStatisticsPublicQueryVariables = Types.Exact<{
  input2: Types.ExamsSummarySelection;
  input: Types.GroupMembersSelectionInput;
}>;


export type BackofficeStatisticsPublicQuery = { __typename?: 'Query', publicGroups: Array<{ __typename?: 'GroupView', me?: { __typename?: 'GroupMemberView', score: number, role: Types.GroupMemberRoleView, memberSince: any, examsSummary: Array<{ __typename?: 'ExamResultsSummary', passedExamsNumber: number, tag: string, totalExamsNumber: number }>, user: { __typename?: 'UserPublicModelView', username: string, id: string } } | null, members: { __typename?: 'GroupMemberViewPaginationResultSetView', total: number, options: { __typename?: 'PaginationOptionsView', limit: number, offset: number }, items: Array<{ __typename?: 'GroupMemberView', score: number, role: Types.GroupMemberRoleView, memberSince: any, user: { __typename?: 'UserPublicModelView', username: string, id: string }, examsSummary: Array<{ __typename?: 'ExamResultsSummary', passedExamsNumber: number, tag: string, totalExamsNumber: number }> }> } }> };

export type GetJoiningRequestestsQueryVariables = Types.Exact<{
  input: Types.JoinRequestsSelectionInput;
  code: Types.Scalars['String']['input'];
}>;


export type GetJoiningRequestestsQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', joinRequests: { __typename?: 'JoinRequestViewPaginationResultSetView', total: number, items: Array<{ __typename?: 'JoinRequestView', createdAt: any, createdBy: { __typename?: 'UserPublicModelView', id: string, username: string } }>, options: { __typename?: 'PaginationOptionsView', offset: number, limit: number } } } | null };

export type GroupJoinRequestReviewMutationVariables = Types.Exact<{
  input: Types.JoinRequestReviewInput;
}>;


export type GroupJoinRequestReviewMutation = { __typename?: 'Mutation', groupJoinRequestReview: { __typename?: 'JoinRequestReviewFailureView', reason: string } | { __typename?: 'JoinRequestReviewSuccessView', groupCode: string, userId: string } };


export const BackofficeStatisticsDocument = gql`
    query BackofficeStatistics($input: GroupMembersSelectionInput!, $input2: ExamsSummarySelection!, $code: String!) {
  group(code: $code) {
    members(input: $input) {
      items {
        user {
          id
          username
        }
        score
        role
        memberSince
        examsSummary(input: $input2) {
          passedExamsNumber
          tag
          totalExamsNumber
        }
      }
    }
    me {
      examsSummary(input: $input2) {
        passedExamsNumber
        tag
        totalExamsNumber
      }
      memberSince
      role
      user {
        username
        id
      }
      score
    }
  }
}
    `;

/**
 * __useBackofficeStatisticsQuery__
 *
 * To run a query within a React component, call `useBackofficeStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      input2: // value for 'input2'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useBackofficeStatisticsQuery(baseOptions: Apollo.QueryHookOptions<BackofficeStatisticsQuery, BackofficeStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackofficeStatisticsQuery, BackofficeStatisticsQueryVariables>(BackofficeStatisticsDocument, options);
      }
export function useBackofficeStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackofficeStatisticsQuery, BackofficeStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackofficeStatisticsQuery, BackofficeStatisticsQueryVariables>(BackofficeStatisticsDocument, options);
        }
export function useBackofficeStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BackofficeStatisticsQuery, BackofficeStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BackofficeStatisticsQuery, BackofficeStatisticsQueryVariables>(BackofficeStatisticsDocument, options);
        }
export type BackofficeStatisticsQueryHookResult = ReturnType<typeof useBackofficeStatisticsQuery>;
export type BackofficeStatisticsLazyQueryHookResult = ReturnType<typeof useBackofficeStatisticsLazyQuery>;
export type BackofficeStatisticsSuspenseQueryHookResult = ReturnType<typeof useBackofficeStatisticsSuspenseQuery>;
export type BackofficeStatisticsQueryResult = Apollo.QueryResult<BackofficeStatisticsQuery, BackofficeStatisticsQueryVariables>;
export const BackofficeStatisticsLeaderboardsDocument = gql`
    query BackofficeStatisticsLeaderboards($selection: LeaderboardSelectionInput!, $code: String!) {
  group(code: $code) {
    leaderboard(selection: $selection) {
      score
      totalExamsNumber
      totalPassedExamsNumber
      user {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useBackofficeStatisticsLeaderboardsQuery__
 *
 * To run a query within a React component, call `useBackofficeStatisticsLeaderboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatisticsLeaderboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeStatisticsLeaderboardsQuery({
 *   variables: {
 *      selection: // value for 'selection'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useBackofficeStatisticsLeaderboardsQuery(baseOptions: Apollo.QueryHookOptions<BackofficeStatisticsLeaderboardsQuery, BackofficeStatisticsLeaderboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackofficeStatisticsLeaderboardsQuery, BackofficeStatisticsLeaderboardsQueryVariables>(BackofficeStatisticsLeaderboardsDocument, options);
      }
export function useBackofficeStatisticsLeaderboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackofficeStatisticsLeaderboardsQuery, BackofficeStatisticsLeaderboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackofficeStatisticsLeaderboardsQuery, BackofficeStatisticsLeaderboardsQueryVariables>(BackofficeStatisticsLeaderboardsDocument, options);
        }
export function useBackofficeStatisticsLeaderboardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BackofficeStatisticsLeaderboardsQuery, BackofficeStatisticsLeaderboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BackofficeStatisticsLeaderboardsQuery, BackofficeStatisticsLeaderboardsQueryVariables>(BackofficeStatisticsLeaderboardsDocument, options);
        }
export type BackofficeStatisticsLeaderboardsQueryHookResult = ReturnType<typeof useBackofficeStatisticsLeaderboardsQuery>;
export type BackofficeStatisticsLeaderboardsLazyQueryHookResult = ReturnType<typeof useBackofficeStatisticsLeaderboardsLazyQuery>;
export type BackofficeStatisticsLeaderboardsSuspenseQueryHookResult = ReturnType<typeof useBackofficeStatisticsLeaderboardsSuspenseQuery>;
export type BackofficeStatisticsLeaderboardsQueryResult = Apollo.QueryResult<BackofficeStatisticsLeaderboardsQuery, BackofficeStatisticsLeaderboardsQueryVariables>;
export const BackofficeStatisticsPublicLeaderboardsDocument = gql`
    query BackofficeStatisticsPublicLeaderboards($selection: LeaderboardSelectionInput!) {
  publicGroups {
    leaderboard(selection: $selection) {
      user {
        id
        username
      }
      totalPassedExamsNumber
      totalExamsNumber
      score
    }
  }
}
    `;

/**
 * __useBackofficeStatisticsPublicLeaderboardsQuery__
 *
 * To run a query within a React component, call `useBackofficeStatisticsPublicLeaderboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatisticsPublicLeaderboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeStatisticsPublicLeaderboardsQuery({
 *   variables: {
 *      selection: // value for 'selection'
 *   },
 * });
 */
export function useBackofficeStatisticsPublicLeaderboardsQuery(baseOptions: Apollo.QueryHookOptions<BackofficeStatisticsPublicLeaderboardsQuery, BackofficeStatisticsPublicLeaderboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackofficeStatisticsPublicLeaderboardsQuery, BackofficeStatisticsPublicLeaderboardsQueryVariables>(BackofficeStatisticsPublicLeaderboardsDocument, options);
      }
export function useBackofficeStatisticsPublicLeaderboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackofficeStatisticsPublicLeaderboardsQuery, BackofficeStatisticsPublicLeaderboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackofficeStatisticsPublicLeaderboardsQuery, BackofficeStatisticsPublicLeaderboardsQueryVariables>(BackofficeStatisticsPublicLeaderboardsDocument, options);
        }
export function useBackofficeStatisticsPublicLeaderboardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BackofficeStatisticsPublicLeaderboardsQuery, BackofficeStatisticsPublicLeaderboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BackofficeStatisticsPublicLeaderboardsQuery, BackofficeStatisticsPublicLeaderboardsQueryVariables>(BackofficeStatisticsPublicLeaderboardsDocument, options);
        }
export type BackofficeStatisticsPublicLeaderboardsQueryHookResult = ReturnType<typeof useBackofficeStatisticsPublicLeaderboardsQuery>;
export type BackofficeStatisticsPublicLeaderboardsLazyQueryHookResult = ReturnType<typeof useBackofficeStatisticsPublicLeaderboardsLazyQuery>;
export type BackofficeStatisticsPublicLeaderboardsSuspenseQueryHookResult = ReturnType<typeof useBackofficeStatisticsPublicLeaderboardsSuspenseQuery>;
export type BackofficeStatisticsPublicLeaderboardsQueryResult = Apollo.QueryResult<BackofficeStatisticsPublicLeaderboardsQuery, BackofficeStatisticsPublicLeaderboardsQueryVariables>;
export const BackofficeStatisticsPublicDocument = gql`
    query BackofficeStatisticsPublic($input2: ExamsSummarySelection!, $input: GroupMembersSelectionInput!) {
  publicGroups {
    me {
      examsSummary(input: $input2) {
        passedExamsNumber
        tag
        totalExamsNumber
      }
      user {
        username
        id
      }
      score
      role
      memberSince
    }
    members(input: $input) {
      total
      options {
        limit
        offset
      }
      items {
        user {
          username
          id
        }
        score
        role
        memberSince
        examsSummary(input: $input2) {
          passedExamsNumber
          tag
          totalExamsNumber
        }
      }
    }
  }
}
    `;

/**
 * __useBackofficeStatisticsPublicQuery__
 *
 * To run a query within a React component, call `useBackofficeStatisticsPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatisticsPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeStatisticsPublicQuery({
 *   variables: {
 *      input2: // value for 'input2'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeStatisticsPublicQuery(baseOptions: Apollo.QueryHookOptions<BackofficeStatisticsPublicQuery, BackofficeStatisticsPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackofficeStatisticsPublicQuery, BackofficeStatisticsPublicQueryVariables>(BackofficeStatisticsPublicDocument, options);
      }
export function useBackofficeStatisticsPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackofficeStatisticsPublicQuery, BackofficeStatisticsPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackofficeStatisticsPublicQuery, BackofficeStatisticsPublicQueryVariables>(BackofficeStatisticsPublicDocument, options);
        }
export function useBackofficeStatisticsPublicSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BackofficeStatisticsPublicQuery, BackofficeStatisticsPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BackofficeStatisticsPublicQuery, BackofficeStatisticsPublicQueryVariables>(BackofficeStatisticsPublicDocument, options);
        }
export type BackofficeStatisticsPublicQueryHookResult = ReturnType<typeof useBackofficeStatisticsPublicQuery>;
export type BackofficeStatisticsPublicLazyQueryHookResult = ReturnType<typeof useBackofficeStatisticsPublicLazyQuery>;
export type BackofficeStatisticsPublicSuspenseQueryHookResult = ReturnType<typeof useBackofficeStatisticsPublicSuspenseQuery>;
export type BackofficeStatisticsPublicQueryResult = Apollo.QueryResult<BackofficeStatisticsPublicQuery, BackofficeStatisticsPublicQueryVariables>;
export const GetJoiningRequestestsDocument = gql`
    query GetJoiningRequestests($input: JoinRequestsSelectionInput!, $code: String!) {
  group(code: $code) {
    joinRequests(input: $input) {
      items {
        createdAt
        createdBy {
          id
          username
        }
      }
      options {
        offset
        limit
      }
      total
    }
  }
}
    `;

/**
 * __useGetJoiningRequestestsQuery__
 *
 * To run a query within a React component, call `useGetJoiningRequestestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJoiningRequestestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJoiningRequestestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetJoiningRequestestsQuery(baseOptions: Apollo.QueryHookOptions<GetJoiningRequestestsQuery, GetJoiningRequestestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJoiningRequestestsQuery, GetJoiningRequestestsQueryVariables>(GetJoiningRequestestsDocument, options);
      }
export function useGetJoiningRequestestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJoiningRequestestsQuery, GetJoiningRequestestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJoiningRequestestsQuery, GetJoiningRequestestsQueryVariables>(GetJoiningRequestestsDocument, options);
        }
export function useGetJoiningRequestestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetJoiningRequestestsQuery, GetJoiningRequestestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJoiningRequestestsQuery, GetJoiningRequestestsQueryVariables>(GetJoiningRequestestsDocument, options);
        }
export type GetJoiningRequestestsQueryHookResult = ReturnType<typeof useGetJoiningRequestestsQuery>;
export type GetJoiningRequestestsLazyQueryHookResult = ReturnType<typeof useGetJoiningRequestestsLazyQuery>;
export type GetJoiningRequestestsSuspenseQueryHookResult = ReturnType<typeof useGetJoiningRequestestsSuspenseQuery>;
export type GetJoiningRequestestsQueryResult = Apollo.QueryResult<GetJoiningRequestestsQuery, GetJoiningRequestestsQueryVariables>;
export const GroupJoinRequestReviewDocument = gql`
    mutation GroupJoinRequestReview($input: JoinRequestReviewInput!) {
  groupJoinRequestReview(input: $input) {
    ... on JoinRequestReviewFailureView {
      reason
    }
    ... on JoinRequestReviewSuccessView {
      groupCode
      userId
    }
  }
}
    `;
export type GroupJoinRequestReviewMutationFn = Apollo.MutationFunction<GroupJoinRequestReviewMutation, GroupJoinRequestReviewMutationVariables>;

/**
 * __useGroupJoinRequestReviewMutation__
 *
 * To run a mutation, you first call `useGroupJoinRequestReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupJoinRequestReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupJoinRequestReviewMutation, { data, loading, error }] = useGroupJoinRequestReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupJoinRequestReviewMutation(baseOptions?: Apollo.MutationHookOptions<GroupJoinRequestReviewMutation, GroupJoinRequestReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupJoinRequestReviewMutation, GroupJoinRequestReviewMutationVariables>(GroupJoinRequestReviewDocument, options);
      }
export type GroupJoinRequestReviewMutationHookResult = ReturnType<typeof useGroupJoinRequestReviewMutation>;
export type GroupJoinRequestReviewMutationResult = Apollo.MutationResult<GroupJoinRequestReviewMutation>;
export type GroupJoinRequestReviewMutationOptions = Apollo.BaseMutationOptions<GroupJoinRequestReviewMutation, GroupJoinRequestReviewMutationVariables>;