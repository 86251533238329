import React from 'react';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import Hero from '../../components/Hero/Hero.tsx';
import Linkedin from '../../icons/Linkedin.tsx';
import { ImageSection } from '../Home/components/ImageSection/ImageSection.tsx';
import './About.scss';

export const About = () => {
    return (
        <div className="about">
            <Header />
            <Hero title="O mnie" />
            {/* <ImageSection variant="white-noPb">
                <div className="imageSection__content">
                    <h2 className="section__title">Cześć!</h2>
                    <p className="section__desc">
                        Nazywam się <strong>Magdalena Paluch</strong> i jestem autorką Licytapki :) Jeżeli masz jakieś pytania, albo chcesz się ze mną skontaktować, napisz do mnie:
                    </p>
                    <p className="section__desc">magdalena@licytapka.pl</p>
                    <p className="section__desc">
                        <a className="section__desc--link" href="https://www.linkedin.com/in/magdapaluch/">
                            <Linkedin />
                            magdapaluch
                        </a>
                    </p>
                </div>
                <img className="imageSection__image" src="./images/magda-keyboard-black.png" alt="" />
            </ImageSection>

            <ImageSection variant="lightestGreen">
                <div className="imageSection__image-wrapper">
                    <div className="loading-img-wrapper">
                        <LogoBlack />
                    </div>
                </div>
                <div className="imageSection__content">
                    <h2 className="section__title">Brydż</h2>
                    <p className="section__desc">Moja przygoda z brydżem zaczęła się w lipcu 2023r. Wtedy po raz pierwszy poznałam zasady gry. Okazały się być bardziej skomplikowane niż przypuszczałam, więc już w sierpniu zaczęłam pisać pierwsze aplikacje, które mogłyby mi pomóc je opanować.</p>
                    <p className="section__desc">Od tamtej pory, Licytapka ewoluowała.</p>
                </div>
            </ImageSection>

            <ImageSection variant="white">
                <div className="imageSection__content">
                    <h2 className="section__title">Pierwsze wersje</h2>
                    <p className="section__desc">Licytapka, na początku czarno-biała i bardzo ograniczona, w późniejszych wersjach zyskała kolory i nowe funkcjonalności.</p>
                    <p className="section__desc">
                        W ostatecznej fazie zaczęłam współpracę z <strong>Bartkiem Banachem</strong>, który stworzył środowisko, bazę danych, backend i proces CI/CD aplikacji.
                    </p>
                    <p className="section__desc">
                        Wszystkie licytacje do Licytapki napisał niezastąpiony <strong>Szymon Ostrowski</strong>, na początku w tabeli w excelu. A wspaniała mensańska grupa MOST SIG wsparła mnie w jej testowaniu.
                    </p>
                    <p className="section__desc">Licytapka się rozrastała i zyskała część dla trenerów brydżowych, która umożliwia wprowadzanie testów, oraz logowanie dla użytkowników. Stworzyłam również podstronę z lekcjami i nauką.</p>
                </div>
                <img className="imageSection__image" src="./images/old-app-mobile.png" alt="Pierwsze wersje Licytapki" />
            </ImageSection> */}
            <ImageSection variant="lightestGreen">
                <img className="imageSection__image" src="./images/hamak.jpg" alt="Magdalena na hamaku" />
                <div className="imageSection__content">
                    <h2 className="section__title">Stwórz ze mną aplikację</h2>
                    <p className="section__desc">
                        Na co dzień zajmuję się <strong>programowaniem i projektowaniem </strong>aplikacji mobilnych oraz stron internetowych. Jeżeli masz pomysł na ciekawą aplikację brydżową, muzyczną lub jakąkolwiek inną związaną z edukacją lub rozwojem, napisz do mnie!
                    </p>
                    <p className="section__desc">
                        <a href="mailTo:magdalena@licytapka.pl" className="link">
                            magdalena@licytapka.pl
                        </a>
                    </p>
                    <p className="section__desc">
                        <a className="section__desc--link" href="https://www.linkedin.com/in/magdapaluch/">
                            <Linkedin />
                            magdapaluch
                        </a>
                    </p>
                </div>
            </ImageSection>

            <Footer />
        </div>
    );
};
